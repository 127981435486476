(<template>
  <page-data-section :title="sectionTitle"
                     :progress="isProgressActive"
                     class="comp-info">
    <div class="comp-info__content-wrapper">
      <div class="comp-info__sections-wrapper">
        <info-section v-for="section in sectionsArr"
                      :key="section.title"
                      :hide-description="true"
                      :section-data="section" />
      </div>
      <company-avatar :avatar="enterpriseLogo"
                      class="comp-info__logo" />
    </div>
    <div v-if="showEditBtn"
         class="comp-info__btn-cont">
      <router-link :to="$makeRoute({name: 'BuyerCompanyInfoEdit'})"
                   class="sk-btn sk-btn--default comp-info__btn">{{ $gettext('Edit') }}</router-link>
    </div>
  </page-data-section>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import InfoSection from '@/components/enterprise/comp_info/InfoSection';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';

  export default {
    asyncData({store}) {
      const myEntId = store.getters['UserInfoStore/enterpriseId'];
      let promiseArr = '';
      if (!store.state.TTEnterpriseInfoStore.companyInfo) {
        promiseArr = [...promiseArr, store.dispatch('TTEnterpriseInfoStore/getEnterprise', myEntId)];
      }

      return promiseArr ? Promise.all(promiseArr) : promiseArr;
    },
    components: {
      'page-data-section': PageDataSection,
      'info-section': InfoSection
    },
    data() {
      return {
        isProgressActive: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userUid', 'userIsAdminOfEnterprise']),
      ...mapState('TTEnterpriseInfoStore', {
        enterpriseName: (state) => state.companyInfo.name || '',
        enterpriseLogo: (state) => state.companyInfo.logo || '',
        enterpriseOrgNumber: (state) => state.companyInfo.org_number || '',
        enterpriseAddressPresent: (state) => !!(state.companyInfo.address && state.companyInfo.address.line_1),
        enterpriseFullAddress: (state) => state.companyInfo.address || {},
        enterpriseAdminEmail: (state) => state.companyInfo.admin_email || '',
        enterpriseWebsite: (state) => state.companyInfo.website || '',
        enterpriseContactPhone: (state) => {
          return state.companyInfo.phoneCode && state.companyInfo.phoneNumber ? `${state.companyInfo.phoneCode}${state.companyInfo.phoneNumber}` : '';
        },
        enterpriseOwnerUid: (state) => state.companyInfo.owner_uid || ''
      }),
      showEditBtn() { return this.userIsAdminOfEnterprise || this.userIsOwnerOfEnterprise || this.$isGodmode(); },
      userIsOwnerOfEnterprise() { return this.userUid == this.enterpriseOwnerUid; },
      sectionTitle() { return this.$gettext('Company information'); },
      sectionsArr() {
        return [
          this.sectionCompName,
          this.sectionOrgNumber,
          this.sectionWebSite,
          this.sectionAddress,
          this.sectionAdminEmail,
          this.sectionContact
        ];
      },
      sectionCompName() {
        return this.enterpriseName ? {
          title: this.$gettext('Company name:'),
          data: [`<b>${this.enterpriseName}</b>`]
        } : {};
      },
      sectionOrgNumber() {
        return this.enterpriseOrgNumber ? {
          title: this.$gettext('Organisation number:'),
          data: [this.enterpriseOrgNumber]
        } : {};
      },
      sectionAddress() {
        return this.enterpriseAddressPresent ? {
          title: this.$gettext('Address:'),
          data: [
            this.enterpriseFullAddress.line_1 || '',
            this.enterpriseFullAddress.line_2 || '',
            this.enterpriseFullAddress.county || '',
            `${this.enterpriseFullAddress.postcode || ''} ${this.enterpriseFullAddress.city || ''}`,
            this.enterpriseFullAddress.country || ''
          ]
        } : {};
      },
      sectionAdminEmail() {
        return this.enterpriseAdminEmail ? {
          title: this.$gettext('Email:'),
          data: [this.enterpriseAdminEmail]
        } : {};
      },
      sectionContact() {
        return this.enterpriseContactPhone ? {
          title: this.$gettext('Company contact:'),
          data: [this.enterpriseContactPhone]
        } : {};
      },
      sectionWebSite() {
        return this.enterpriseWebsite ? {
          title: this.$gettext('Website:'),
          data: [this.enterpriseWebsite]
        } : {};
      }
    }
  };
</script>

<style scoped>
  .comp-info__content-wrapper {
    display: flex;
    flex-grow: 1;
    padding-bottom: 25px;
  }

  .comp-info__sections-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 380px;
    padding: 15px 20px 0 0;
  }

  .comp-info__logo {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    margin: 18px auto;
  }

  .comp-info__btn-cont {
    display: flex;
  }

  .comp-info__btn {
    width: auto;
    padding: 0 20px;
  }

  @media (max-width: 600px) {
    .comp-info__content-wrapper {
      flex-direction: column-reverse;
    }

    .comp-info__sections-wrapper {
      width: 100%;
      max-width: initial;
      padding: 0;
    }

    .comp-info__logo {
      margin: 0;
      margin-bottom: 40px;
    }
  }
</style>
